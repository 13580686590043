@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');


.admin-login-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
  }
  
  .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 150px;
  }

  h2 {
    margin-top: 150px;
  }

  .centered-content img {
    justify-content: center;
    margin-bottom: 20px;
    height: 150px;
  }
  
  .logo {
    width: 100px; 
    height: auto; 
  }

  .logoAdmin {
    width: 150px; 
    height: auto;
    margin-bottom: 40px;
  }
  
  form {
  display: flex;
  flex-direction: column;
  }
  
  label {
    margin-bottom: 8px;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 100%;
    margin-bottom: 16px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
  }
  
  input[type="text"],
  input[type="password"] {
    background-color: rgba(245, 165, 71, 0.3);
    border: 1px solid #F5A547;
  }
  
  button {
    background-color: #F5A547;
    border: 1px solid #F5A547;
    cursor: pointer;
    padding: 20px;
    font-size: 20px;
  }
  
  @media (max-width: 768px) {
  .centered-content img {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -50px; 
  }

  h2 {
    margin-top: 50px;
  }
}