@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
}

.container {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: url("assets/gradient.svg");
  background-size: 80% 80%; 
  background-position: top left -80%;
  background-repeat:no-repeat;
}

.text-container {
  flex: 1;
  text-align: center;
  padding: 20px;
  position: relative;
  top: 80px;
}

.logo {
  width: 150px;
  height: 100px;
  flex-shrink: 0;
  position: absolute;
  top: -20px;
  left: 60px;
}

.hagit-text{
  color: #222;
  font-size: 40px;
  font-style: normal;
  font-weight: 600; 
  text-align: left;
  margin: 40px;
  margin-top: 70px;
}

.hagit-text span {
color: #F7941D;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 15px;
}

.hagit-small-text {
color: #222;
font-size: 16px;
font-weight: 400;
line-height: 15px;
text-align: left;
margin: 40px;
}

.hagit-small-text span a {
color: #127CC7;
font-size: 16px;
font-weight: 500;
line-height: 15px;
text-decoration-line: underline;
}

.image-container {
  flex: 1;
  text-align: center;
  margin-top: 200px;
}

.image {
  width: 200px;
  height: 200px;
  flex-shrink: 0;
}

.password-container {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.password-container p {
  flex: 1;
  text-align: center;
  color: #222;
  font-size: 16px;
  font-weight: 400;
}
  
.password-container span a {
  color: #127CC7;
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
  }

form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 100px;
}

input[type="password"] {
  width: calc(0% - 20px);
  padding: 20px;
  color: #7F8192;
  margin-bottom: 10px;
  border: 1px solid #F5A547;
  border-radius: 5px;
  background-color: #f5a4475a;
  outline: none;
  font-size: 25px;
}

input[type="submit"] {
  width: 30%; 
  padding: 15px;
  border: 1px solid #F5A547;
  border-radius: 5px;
  background-color: #F5A547;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 15px;
}

input[type="submit"]:hover {
  background-color: none;
  color: #fff;
}



/* Mobile */

@media (max-width: 768px) {
  .container {
    flex-direction: column; 
    background-size: cover;
    background-position: center; 
  }

  .logo {
    position: static; 
    margin-bottom: -10px; 
  }

  .text-container {
    flex: 1;
    text-align: center;
    position: relative;
  }

  .hagit-text {
    font-size: 30px;
  }

  .hagit-text span {
    font-size: 30px;
  }

  .image-container {
    display: none
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 80px;
  }

.password-container {
  flex: 1;
  text-align: center;
  padding: 20px;
  margin-right: 90px;
}

  input[type="password"] {
    width: 100%;
    padding: 15px;
    color: #7F8192;
    margin-bottom: 10px;
    border: 1px solid #F5A547;
    border-radius: 5px;
    background-color: #f5a4475a;
    outline: none;
    font-size: 10px;
  }
  
  input[type="submit"] {
    width: calc(100% - 100px); 
    padding: 10px;
    border: 1px solid #F5A547;
    border-radius: 5px;
    background-color: #F5A547;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  input[type="submit"]:hover {
    background-color: none;
    color: #fff;
  }

  .hagit-small-text,
  .hagit-small-text span {
    font-size: 14px;
  
  }
}