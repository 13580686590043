@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

h3 {
  font-weight: 400;
  text-transform: capitalize;
}

.form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.left-side {
  flex: 1;
}

.hagiter {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: cover; 
}

.right-side {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

form {
  width: 100%;
  max-width: 400px; /* Adjust the maximum width as needed */
  text-align: center;
  margin: auto;
}

label {
  margin-top: 10px;
  display: block;
}

input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: #f7951d25;
  border: 1px solid #F7941D;
  border-radius: 10px;
  color: #7F8192;
  font-family: 'Manrope';
}
.checkbox-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-options label {
  display: flex;
  align-items: center;
  background-color: #f7951d25;
  border: 1px solid #F7941D;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  flex-basis: calc(50% - 10px); /* 50% width with 10px gap */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: #f7951d25;
  border: 1px solid #F7941D;
  border-radius: 10px;
  color: #7F8192;
  font-family: 'Manrope';
}

.checkbox-options input {
  margin-right: 5px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #F7941D;
  border-radius: 5px;
  width: 16px;
  height: 16px;
  outline: none;
  vertical-align: middle;
  position: relative;
  top: -1px;
  cursor: pointer;
}

.checkbox-options input:checked {
  background-color: #F7941D;
}



.checkbox-options input {
  margin-right: 5px;
}


input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

#payment {
  content: 'Select some files';
  display: inline-block;
  background: #f7951d25;
  border: 1px solid #F7941D;
  border-radius: 10px;
  padding: 10px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
}

#payment:hover::before {
  border-color: black;
}

#paymentactive::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.popup-ok-button {
  padding: 8px 16px; /* Adjust as needed */
  font-size: 14px; /* Adjust as needed */
}

button {
  background-color: #F7941D;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .form-container {
    flex-direction: row;
  }

  .left-side,
  .right-side {
    flex: 1;
  }
}
