@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
  
  .download-buttons {
    display: flex;
    margin-bottom: 10px;
  }
  
  .download-btn, .print-btn {
    padding: 6px 12px;
    margin-right: 0;
    background-color: #0e639f;
    color: #fff;
    border: none;
    border-radius: 0;
    cursor: pointer;
    width: 5%;
    font-family: 'Manrope';
  }
  
  .download-btn:hover, .print-btn:hover {
    background-color: #2980b9;
  }

  .csv-button {
    text-decoration: none;
    text-align: center;
  }
  
  @media print {
    body * {
      visibility: hidden;
    }
    .printable-table,
    .printable-table * {
      visibility: visible;
    }
    .printable-table {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  
  @media print {
    .download-buttons {
      display: none;
    }
  }
  